import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Cores from '@core/constants/Cores';
import Roles, { RoleColor } from '@core/constants/Roles';
import { Avatar, Dropdown, Icon, Menu, Tag } from 'antd';
import styled from 'styled-components';
import { userSignOut } from '@core/redux/actions/Auth';
import { FieldControl, Label, Flexbox, LojaSessao } from '@core/components';
import { useHistory } from 'react-router-dom';

const TopbarMenu = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: row;
  float: right;
  height: 100%;
  list-style: none;
  margin: 0 16px;
  padding: 0px;
  width: 100%;
`;

const TopbarMenuItem = styled.li`
  color: #003366;
  display: inline;
  font-size: 24px;
  margin-left: 24px;

  & .ant-badge {
    font-size: 24px;
    margin: 0;
  }
`;

const AvatarUser = styled(Avatar)`
  box-shadow: 0 0 10px 0 ${Cores.primaria};
  cursor: pointer;
`;

export default function Topbar() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loja, setLoja] = useState();
  const { authUser } = useSelector((state) => state.auth);

  const getAvatarUser = () => {
    if (authUser && !authUser.photo_url) {
      return (<AvatarUser size={48} style={{ cursor: 'pointer' }} icon="user" />);
    }

    if (authUser && authUser.photo_url) {
      return (<AvatarUser size={48} src={authUser.photo_url} />);
    }
    return (<AvatarUser size={48} style={{ cursor: 'pointer' }} icon="user" />);
  };

  useEffect(() => {
    if (!!authUser.lojas && authUser.lojas.length > 0) {
      setLoja(authUser.lojas[0]);
    }
  }, [authUser]);

  return (
    <TopbarMenu>
      {!!loja && !!loja.empresa && (
        <TopbarMenuItem>
          <FieldControl label="Empresa">
            <Flexbox flexDirection="row">
              <Label bold>{loja.empresa.nome_fantasia}</Label>
            </Flexbox>
          </FieldControl>
        </TopbarMenuItem>
      )}
      {!!loja && (
        <TopbarMenuItem>
          <LojaSessao />
        </TopbarMenuItem>
      )}
      <div style={{ flex: 1 }} />
      <TopbarMenuItem>
        <Dropdown
          overlay={(
            <Menu>
              <div className="gx-p-4 gx-text-center">
                <Avatar size={64} src={authUser.photo_url} className="gx-mb-3" />
                <Label block center bold>{authUser.display_name || authUser.username}</Label>
                <Tag color={RoleColor[authUser.roles]} className="gx-mt-2">{Roles[authUser.roles]}</Tag>
              </div>
              <Menu.Divider />
              <Menu.Item key={1} className="gx-py-2" onClick={() => history.push('/perfil')}>
                <Icon type="user" />
                {' '}
                Meu Perfil
              </Menu.Item>
              <Menu.Item key={2} className="gx-py-2" onClick={() => dispatch(userSignOut())}>
                <Icon type="logout" />
                {' '}
                Sair
              </Menu.Item>
            </Menu>
          )} trigger={['click']}
        >
          {getAvatarUser()}
        </Dropdown>
      </TopbarMenuItem>
    </TopbarMenu>
  );
}
