import React from 'react';
import styled from 'styled-components';
import { Loading } from '@core/components';

const Container = styled.div`
  margin: 0 auto;
  padding: 16px;
  width: 90%;

  @media screen and (max-width: 1200px) {
    width: 95%;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export default function PageContainer({ children, loading, ...props }) {
  return (
    <Container {...props}>
      <Loading loading={loading}>
        {children}
      </Loading>
    </Container>
  );
}
