import React from 'react';

import moment from 'moment';
import version from 'app/version';

export default function Version() {
  const { major, minor, fix, date } = version;

  return (
    <span className="gx-text-light-grey"><small>{`v${major}.${minor}.${fix}-${moment(date).format('YYYYMMDD')}`}</small></span>
  );
}
