import React from 'react'
import { Typography } from 'antd'

const { Paragraph } = Typography

const ParametroEditItem = ({ title, icon, description, readonly = false, onChange }) => {

    return (
        <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
            {icon && (
                <div className="gx-mr-3">
                    <i className={`icon icon-${icon} gx-fs-xlxl gx-text-orange`} />
                </div>
            )}
            <div className="gx-media-body" style={{ minHeight: 50 }}>
                <h6 className="gx-mb-1 gx-text-grey">{title}</h6>
                {!readonly && (<Paragraph editable={{ onChange }}>{description}</Paragraph>)}
                {readonly && description && (<p className="gx-mb-0">{description}</p>)}
            </div>
        </div>
    )
}

export default ParametroEditItem
