import React from 'react';

import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import RestrictedRoute from '@core/components/security/RestrictedRoute';

import LayoutApp from './LayoutApp';

const Login = loadable(() => import('app/auth/Login'));
const Forgot = loadable(() => import('app/auth/Forgot'));
const Change = loadable(() => import('app/auth/ChangePassword'));

export default function MainApp() {
  const { token } = useSelector((state) => state.auth);
  return (
    <Router>
      <Switch>
        <Route path="/entrar">
          <Login />
        </Route>

        <Route path="/esqueci">
          <Forgot />
        </Route>

        <Route path="/alterar/:uid">
          <Change />
        </Route>

        <RestrictedRoute token={token} path="*">
          <LayoutApp />
        </RestrictedRoute>

      </Switch>
    </Router>
  );
}
