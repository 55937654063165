import React, { forwardRef } from 'react';

import { Select } from 'antd';
import { Ufs } from '@core/constants/Ufs';

const { Option } = Select;
const EstadosSelect = ({ type = 'nomes', textOnly = false, width, ...props }, ref) => {
  const data = Object.keys(Ufs).map((key) => Ufs[key]);
  return (
    <span ref={ref}>
      <Select
        {...props}
        showSearch
        optionFilterProp="label"
        style={{ width: width || '100%' }}
      >
        {data.map((uf) => (
          <Option
            key={uf.id}
            label={`${uf.sigla}_${uf.nome}`}
            value={textOnly ? uf.sigla : uf.id}
          >
            {type === 'siglas' ? uf.sigla : uf.nome}
          </Option>
        ))}
      </Select>
    </span>
  );
};

export default forwardRef(EstadosSelect);
