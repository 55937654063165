import moment from 'moment';
import humanizeDuration from 'humanize-duration';

import 'moment-duration-format';

export function Limpar(valor) {
  if (!valor) return null;

  const term = String(valor);
  // eslint-disable-next-line
  return term.replace(/(\(|\)|\.|\/|\-|\_)/g, '').replace(/ /g, '').trim()
}

export function Cpf(valor) {
  if (!valor) return null;

  const pure = Limpar(valor);

  if (pure.length >= 11) {
    // eslint-disable-next-line
    return pure.substr(0, 11).replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4")
  }
  if (pure.length < 11) {
    let cpf = '';

    for (let i = 0; i < pure.length; i++) {
      cpf += pure[i];
      if (i === 2 || i === 5) cpf += '.';
      if (i === 8) cpf += '-';
    }

    return cpf;
  }
}

export function Telefone(valor) {
  if (!valor) return null;
  if (valor[2] === '9') {
    // eslint-disable-next-line
    return valor.replace(/(\d{2})(\d{5})(\d{4})/g, "\(\$1\) \$2\-\$3")
  }
  // eslint-disable-next-line
  return valor.replace(/(\d{2})(\d{4})(\d{4})/g, "\(\$1\) \$2\-\$3")
}

export function Cep(valor) {
  if (!valor) return null;
  // eslint-disable-next-line
  return valor.replace(/(\d{5})(\d{3})/g, "\$1\-\$2")
}

export function Cnpj(valor) {
  if (!valor) return null;
  // eslint-disable-next-line
  return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")
}

export function Valor(valor, semSimbolo = false) {
  const tValor = (valor === null || valor === undefined) ? 0.00 : valor;

  // eslint-disable-next-line
  const result = parseFloat(tValor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })

  if (semSimbolo) { return result.toLowerCase().replace('r$', '').trim(); }

  return result.trim();
}

export function DuracaoMinutos(duracao /* moment object */) {
  return (duracao.hours() * 60) + duracao.minutes();
}

export function MinutosEmHoras(mins, mask = 'HH:mm') {
  // do not include the first validation check if you want, for example,
  // getTimeFromMins(1530) to equal getTimeFromMins(90) (i.e. mins rollover)
  // if (mins >= 24 * 60 || mins < 0) {
  //   throw new RangeError('A entrada válida deve ser maior ou igual a 0 e menor que 1440.');
  // }
  // eslint-disable-next-line no-bitwise
  const h = mins / 60 | 0;
  // eslint-disable-next-line no-bitwise
  const m = mins % 60 | 0;
  return moment.utc().hours(h).minutes(m).format(mask);
}

export function SegundosEmHoras(segs, mask = 'HH:mm:ss') {
  return moment('1900-01-01 00:00:00').add(segs, 'seconds').format(mask);
}

export function TempoMinutos(valor, mascara = 'HH[h] mm[min]') {
  if (!valor) return null;
  return moment().startOf('day').add(valor, 'minutes').format(mascara)
    .replace('00h ', '')
    .replace('00min', '');
}

export function TempoMinutosMoment(valor, mascara = 'HH:mm') {
  if (!valor) return null;
  return moment(TempoMinutos(valor, mascara), mascara);
}

export function DataTmz(data, format) {
  if (!data) return null;

  let tempData = data;
  if (!tempData.includes('T') && tempData.length === 10) { tempData = `${tempData}T03:00:00.000Z`; }
  tempData = tempData.replace('T00:00:00.000Z', 'T03:00:00.000Z');
  if (format) return moment(tempData).format(format);
  return moment(tempData);
}

export function Data(data, formato = 'DD/MM/YYYY') {
  return moment(data).format(formato);
}

export function DataSql(data, formato = 'YYYY-MM-DD') {
  if (!data) return null;
  return moment(data).format(formato);
}

export function Hora(hora, formato = 'HH:mm') {
  return moment(hora).format(formato);
}

export function InicialMaiuscula(str) {
  return str.replace(
    /\w\S*/g,
    (txt) => {
      if (txt.length <= 2) return txt.toLowerCase();
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    },
  );
}

export function Duracao(date) {
  const data = moment(date).valueOf();
  const agora = moment().valueOf();
  const tempo = agora - data;
  const meses = moment.duration(tempo).months();

  if (meses <= 1) return `${humanizeDuration(agora - data, { language: 'pt', largest: 1, round: true })} atrás`;
  return moment(date).format('DD MMMM YYYY, HH:mm');
}

export const ValorDecimal = (maskedValue = '') => {
  if (!maskedValue) return maskedValue;
  const factor = maskedValue[0] === '-' ? -1 : 1;
  // eslint-disable-next-line radix
  const valor = parseInt(maskedValue.toString().replace(/\xA0/g, ' ').replace(/\D/g, '') || 0, 10) / 100;

  return valor * factor;
};

export const Idade = (data) => {
  if (!data) return '';
  const duration = moment.duration(moment().diff(data));
  const anos = duration.years();
  const meses = duration.months();

  return `${anos} anos ${meses > 0 ? `${meses} meses` : ''}`;
};

export const OouA = (sexo, { prefix, sufix, uppercase }) => {
  let result = 'o';

  if (sexo) {
    if (sexo.toLowerCase() === 'feminino') {
      result = 'a';
    } else if (sexo.toLowerCase() === 'masculino') {
      result = 'o';
    } else {
      result = 'o(a)';
    }
  }
  if (uppercase) { result = result.toUpperCase(); }

  return `${prefix || ''}${result}${sufix || ''}`;
};
