import {
  SESSAO_LOJA,
  SESSAO_EMPRESA,
} from '../actions/Sessao';

const INIT_STATE = {
  loja: null,
  empresa: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SESSAO_LOJA: {
      return { ...state, loja: action.payload };
    }
    case SESSAO_EMPRESA: {
      return { ...state, empresa: action.payload };
    }
    default:
      return state;
  }
};
