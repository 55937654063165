const Cores = {
  primaria: '#003366',
  secundaria: '#f2c600',
  branco: '#fff',
  cinzaClaro: '#e3e3e3',
  verde: '#4ab858',
  vermelho: '#ff4d4f',
  cinzaEscuro: '#949494',
  azulMedio: '#395a7a',
};

export default Cores;
