import React from 'react';
import styled from 'styled-components';

const themes = {
  default: {
    color: '#395a7a',
    hoverColor: '#b7cdfd',
    backgroundColor: '#fff',
  },
  blue: {
    color: '#fff',
    hoverColor: '#b7cdfd',
    backgroundColor: '#395a7a',
  },
};

const WidgetPrefix = styled.div`
  margin-right: 16px;
`;
const WidgetToolbar = styled.div``;

const WidgetBody = styled.div`
  height: 100%;
  margin-top: 16px;
  width: 100%;
  ${(props) => (props.overflow
    ? `overflow: ${props.overflow}`
    : '')}
`;

const WidgetTitle = styled.div`
  color: ${(props) => (props.color ? props.color : themes[props.theme].color)};
  font-size: 24px;
  font-weight: ${(props) => (props.bold ? 900 : 400)};
  line-height: 1.1;
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'normal')};
`;

const WidgetHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const WidgetContainer = styled.div`
  background: ${(props) => themes[props.theme].backgroundColor};
  border: 1px solid #c9d3dd;
  border-radius: 3px;
  box-shadow: 0 0 10px 0 rgba(201,211,221,.4);
  overflow: auto;
  padding: 30px;
  transition: all .25s ease;

  &:hover {
    border: 1px solid ${(props) => themes[props.theme].hoverColor};
    box-shadow: 0 2px 4px 0 #c1c3c7;
    z-index: 100;
  }
`;

const Widget = ({ prefix, title, toolbar, theme = 'default', overflow, children, ...props }) => (
  <WidgetContainer {...props} theme={theme}>
    <WidgetHeader>
      {prefix && (<WidgetPrefix>{prefix}</WidgetPrefix>)}
      <WidgetTitle {...props} theme={theme}>{title}</WidgetTitle>
      <WidgetToolbar>{toolbar}</WidgetToolbar>
    </WidgetHeader>
    <WidgetBody overflow={overflow}>
      {children}
    </WidgetBody>
  </WidgetContainer>
);

export default Widget;
