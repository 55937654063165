import React, { forwardRef } from 'react'

import { Avatar, Select } from 'antd'

const { Option, OptGroup } = Select

const ColorItem = ({ color, width = 24 }) => (
    <Avatar shape="square" style={{ backgroundColor: color }} size={width} />
    // <div style={{ backgroundColor: color, minWidth: 50, width: width || '100%' }}>&nbsp;</div>
)
const CoresSelect = (props, ref) => {
    const { width } = props

    return (
        <span ref={ref}>
            <Select
                style={{ width: width || '100%' }}
                {...props}
            >
                <OptGroup label="Vermelho">
                    <Option value="#CD5C5C"><ColorItem color="#CD5C5C" /></Option>
                    <Option value="#F08080"><ColorItem color="#F08080" /></Option>
                    <Option value="#FA8072"><ColorItem color="#FA8072" /></Option>
                    <Option value="#E9967A"><ColorItem color="#E9967A" /></Option>
                    <Option value="#FFA07A"><ColorItem color="#FFA07A" /></Option>
                    <Option value="#DC143C"><ColorItem color="#DC143C" /></Option>
                    <Option value="#FF0000"><ColorItem color="#FF0000" /></Option>
                    <Option value="#B22222"><ColorItem color="#B22222" /></Option>
                    <Option value="#8B0000"><ColorItem color="#8B0000" /></Option>
                </OptGroup>
                <OptGroup label="Rosa">
                    <Option value="#FFC0CB"><ColorItem color="#FFC0CB" /></Option>
                    <Option value="#FFB6C1"><ColorItem color="#FFB6C1" /></Option>
                    <Option value="#FF69B4"><ColorItem color="#FF69B4" /></Option>
                    <Option value="#FF1493"><ColorItem color="#FF1493" /></Option>
                    <Option value="#C71585"><ColorItem color="#C71585" /></Option>
                    <Option value="#DB7093"><ColorItem color="#DB7093" /></Option>
                </OptGroup>
                <OptGroup label="Laranja">
                    <Option value="#FFA07A"><ColorItem color="#FFA07A" /></Option>
                    <Option value="#FF7F50"><ColorItem color="#FF7F50" /></Option>
                    <Option value="#FF6347"><ColorItem color="#FF6347" /></Option>
                    <Option value="#FF4500"><ColorItem color="#FF4500" /></Option>
                    <Option value="#FF8C00"><ColorItem color="#FF8C00" /></Option>
                    <Option value="#FFA500"><ColorItem color="#FFA500" /></Option>
                </OptGroup>
                <OptGroup label="Amarelo">
                    <Option value="#FFD700"><ColorItem color="#FFD700" /></Option>
                    <Option value="#FFFF00"><ColorItem color="#FFFF00" /></Option>
                    <Option value="#FFFFE0"><ColorItem color="#FFFFE0" /></Option>
                    <Option value="#FFFACD"><ColorItem color="#FFFACD" /></Option>
                    <Option value="#FAFAD2"><ColorItem color="#FAFAD2" /></Option>
                    <Option value="#FFEFD5"><ColorItem color="#FFEFD5" /></Option>
                    <Option value="#FFE4B5"><ColorItem color="#FFE4B5" /></Option>
                    <Option value="#FFDAB9"><ColorItem color="#FFDAB9" /></Option>
                    <Option value="#EEE8AA"><ColorItem color="#EEE8AA" /></Option>
                    <Option value="#F0E68C"><ColorItem color="#F0E68C" /></Option>
                    <Option value="#BDB76B"><ColorItem color="#BDB76B" /></Option>
                </OptGroup>
                <OptGroup label="Roxo">
                    <Option value="#E6E6FA"><ColorItem color="#E6E6FA" /></Option>
                    <Option value="#D8BFD8"><ColorItem color="#D8BFD8" /></Option>
                    <Option value="#DDA0DD"><ColorItem color="#DDA0DD" /></Option>
                    <Option value="#EE82EE"><ColorItem color="#EE82EE" /></Option>
                    <Option value="#DA70D6"><ColorItem color="#DA70D6" /></Option>
                    <Option value="#FF00FF"><ColorItem color="#FF00FF" /></Option>
                    <Option value="#BA55D3"><ColorItem color="#BA55D3" /></Option>
                    <Option value="#9370DB"><ColorItem color="#9370DB" /></Option>
                    <Option value="#663399"><ColorItem color="#663399" /></Option>
                    <Option value="#8A2BE2"><ColorItem color="#8A2BE2" /></Option>
                    <Option value="#9400D3"><ColorItem color="#9400D3" /></Option>
                    <Option value="#9932CC"><ColorItem color="#9932CC" /></Option>
                    <Option value="#8B008B"><ColorItem color="#8B008B" /></Option>
                    <Option value="#800080"><ColorItem color="#800080" /></Option>
                    <Option value="#4B0082"><ColorItem color="#4B0082" /></Option>
                    <Option value="#6A5ACD"><ColorItem color="#6A5ACD" /></Option>
                    <Option value="#483D8B"><ColorItem color="#483D8B" /></Option>
                    <Option value="#7B68EE"><ColorItem color="#7B68EE" /></Option>
                </OptGroup>
                <OptGroup label="Verde">
                    <Option value="#ADFF2F"><ColorItem color="#ADFF2F" /></Option>
                    <Option value="#7FFF00"><ColorItem color="#7FFF00" /></Option>
                    <Option value="#7CFC00"><ColorItem color="#7CFC00" /></Option>
                    <Option value="#00FF00"><ColorItem color="#00FF00" /></Option>
                    <Option value="#32CD32"><ColorItem color="#32CD32" /></Option>
                    <Option value="#98FB98"><ColorItem color="#98FB98" /></Option>
                    <Option value="#90EE90"><ColorItem color="#90EE90" /></Option>
                    <Option value="#00FA9A"><ColorItem color="#00FA9A" /></Option>
                    <Option value="#00FF7F"><ColorItem color="#00FF7F" /></Option>
                    <Option value="#3CB371"><ColorItem color="#3CB371" /></Option>
                    <Option value="#2E8B57"><ColorItem color="#2E8B57" /></Option>
                    <Option value="#228B22"><ColorItem color="#228B22" /></Option>
                    <Option value="#008000"><ColorItem color="#008000" /></Option>
                    <Option value="#006400"><ColorItem color="#006400" /></Option>
                    <Option value="#9ACD32"><ColorItem color="#9ACD32" /></Option>
                    <Option value="#6B8E23"><ColorItem color="#6B8E23" /></Option>
                    <Option value="#808000"><ColorItem color="#808000" /></Option>
                    <Option value="#556B2F"><ColorItem color="#556B2F" /></Option>
                    <Option value="#66CDAA"><ColorItem color="#66CDAA" /></Option>
                    <Option value="#8FBC8B"><ColorItem color="#8FBC8B" /></Option>
                    <Option value="#20B2AA"><ColorItem color="#20B2AA" /></Option>
                    <Option value="#008B8B"><ColorItem color="#008B8B" /></Option>
                    <Option value="#008080"><ColorItem color="#008080" /></Option>
                </OptGroup>
                <OptGroup label="Azul">
                    <Option value="#00FFFF"><ColorItem color="#00FFFF" /></Option>
                    <Option value="#E0FFFF"><ColorItem color="#E0FFFF" /></Option>
                    <Option value="#AFEEEE"><ColorItem color="#AFEEEE" /></Option>
                    <Option value="#7FFFD4"><ColorItem color="#7FFFD4" /></Option>
                    <Option value="#40E0D0"><ColorItem color="#40E0D0" /></Option>
                    <Option value="#48D1CC"><ColorItem color="#48D1CC" /></Option>
                    <Option value="#00CED1"><ColorItem color="#00CED1" /></Option>
                    <Option value="#5F9EA0"><ColorItem color="#5F9EA0" /></Option>
                    <Option value="#4682B4"><ColorItem color="#4682B4" /></Option>
                    <Option value="#B0C4DE"><ColorItem color="#B0C4DE" /></Option>
                    <Option value="#B0E0E6"><ColorItem color="#B0E0E6" /></Option>
                    <Option value="#ADD8E6"><ColorItem color="#ADD8E6" /></Option>
                    <Option value="#87CEEB"><ColorItem color="#87CEEB" /></Option>
                    <Option value="#87CEFA"><ColorItem color="#87CEFA" /></Option>
                    <Option value="#00BFFF"><ColorItem color="#00BFFF" /></Option>
                    <Option value="#1E90FF"><ColorItem color="#1E90FF" /></Option>
                    <Option value="#6495ED"><ColorItem color="#6495ED" /></Option>
                    <Option value="#7B68EE"><ColorItem color="#7B68EE" /></Option>
                    <Option value="#4169E1"><ColorItem color="#4169E1" /></Option>
                    <Option value="#0000FF"><ColorItem color="#0000FF" /></Option>
                    <Option value="#0000CD"><ColorItem color="#0000CD" /></Option>
                    <Option value="#00008B"><ColorItem color="#00008B" /></Option>
                    <Option value="#000080"><ColorItem color="#000080" /></Option>
                    <Option value="#191970"><ColorItem color="#191970" /></Option>
                </OptGroup>
                <OptGroup label="Marrom">
                    <Option value="#FFF8DC"><ColorItem color="#FFF8DC" /></Option>
                    <Option value="#FFEBCD"><ColorItem color="#FFEBCD" /></Option>
                    <Option value="#FFE4C4"><ColorItem color="#FFE4C4" /></Option>
                    <Option value="#FFDEAD"><ColorItem color="#FFDEAD" /></Option>
                    <Option value="#F5DEB3"><ColorItem color="#F5DEB3" /></Option>
                    <Option value="#DEB887"><ColorItem color="#DEB887" /></Option>
                    <Option value="#D2B48C"><ColorItem color="#D2B48C" /></Option>
                    <Option value="#BC8F8F"><ColorItem color="#BC8F8F" /></Option>
                    <Option value="#F4A460"><ColorItem color="#F4A460" /></Option>
                    <Option value="#DAA520"><ColorItem color="#DAA520" /></Option>
                    <Option value="#B8860B"><ColorItem color="#B8860B" /></Option>
                    <Option value="#CD853F"><ColorItem color="#CD853F" /></Option>
                    <Option value="#D2691E"><ColorItem color="#D2691E" /></Option>
                    <Option value="#8B4513"><ColorItem color="#8B4513" /></Option>
                    <Option value="#A0522D"><ColorItem color="#A0522D" /></Option>
                    <Option value="#A52A2A"><ColorItem color="#A52A2A" /></Option>
                    <Option value="#800000"><ColorItem color="#800000" /></Option>
                </OptGroup>
                <OptGroup label="Branco">
                    <Option value="#FFFFFF"><ColorItem color="#FFFFFF" /></Option>
                    <Option value="#FFFAFA"><ColorItem color="#FFFAFA" /></Option>
                    <Option value="#F0FFF0"><ColorItem color="#F0FFF0" /></Option>
                    <Option value="#F5FFFA"><ColorItem color="#F5FFFA" /></Option>
                    <Option value="#F0FFFF"><ColorItem color="#F0FFFF" /></Option>
                    <Option value="#F0F8FF"><ColorItem color="#F0F8FF" /></Option>
                    <Option value="#F8F8FF"><ColorItem color="#F8F8FF" /></Option>
                    <Option value="#F5F5F5"><ColorItem color="#F5F5F5" /></Option>
                    <Option value="#FFF5EE"><ColorItem color="#FFF5EE" /></Option>
                    <Option value="#F5F5DC"><ColorItem color="#F5F5DC" /></Option>
                    <Option value="#FDF5E6"><ColorItem color="#FDF5E6" /></Option>
                    <Option value="#FFFAF0"><ColorItem color="#FFFAF0" /></Option>
                    <Option value="#FFFFF0"><ColorItem color="#FFFFF0" /></Option>
                    <Option value="#FAEBD7"><ColorItem color="#FAEBD7" /></Option>
                    <Option value="#FAF0E6"><ColorItem color="#FAF0E6" /></Option>
                    <Option value="#FFF0F5"><ColorItem color="#FFF0F5" /></Option>
                    <Option value="#FFE4E1"><ColorItem color="#FFE4E1" /></Option>
                </OptGroup>
                <OptGroup label="Cinza">
                    <Option value="#DCDCDC"><ColorItem color="#DCDCDC" /></Option>
                    <Option value="#D3D3D3"><ColorItem color="#D3D3D3" /></Option>
                    <Option value="#C0C0C0"><ColorItem color="#C0C0C0" /></Option>
                    <Option value="#A9A9A9"><ColorItem color="#A9A9A9" /></Option>
                    <Option value="#808080"><ColorItem color="#808080" /></Option>
                    <Option value="#696969"><ColorItem color="#696969" /></Option>
                    <Option value="#778899"><ColorItem color="#778899" /></Option>
                    <Option value="#708090"><ColorItem color="#708090" /></Option>
                    <Option value="#2F4F4F"><ColorItem color="#2F4F4F" /></Option>
                    <Option value="#000000"><ColorItem color="#000000" /></Option>
                </OptGroup>
            </Select>
        </span>
    );
}

export default forwardRef(CoresSelect)
