import styled from 'styled-components';
import Layout from 'antd/lib/layout';

export const Sidebar = styled(Layout.Sider)`
  height: 100vh;
  overflow: auto;
  position: fixed;

  & .ant-layout-sider-trigger {
    align-items: center;
    display: flex;
    height: 47px;
    justify-content: center;
  }
`;

export const Logo = styled.div`
    align-items: center;
    display:flex;
    height: 72px;
    justify-content: center;
    margin-bottom: 24px;
    text-align: center;
  & > img {
    height: ${({ collapsed }) => (collapsed ? '11px' : '22px')};
    transition: all .3s ease;
    width: ${({ collapsed }) => (collapsed ? '60px' : '120px')};
  }
`;
