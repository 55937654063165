import jwtDecode from 'jwt-decode';
import { useApiPublic } from '@core/hooks';
import * as Constants from '@core/constants';
import { getAuthStorageItem } from '@core/security';

const { api: ApiPublic } = useApiPublic();

export async function signInWithEmailAndPassword(username, password) {
  try {
    const { data: user } = await ApiPublic({
      username,
      password,
    }).post('/authenticate');
    return user;
  } catch (err) {
    if (err && err.response) {
      return {
        message: 'Usuário não encontrado',
        error: {
          data: err.response.data,
          status: err.response.status,
          statusText: err.response.statusText,
        },
      };
    }
    if (err) {
      return {
        message: 'Usuário e senha inválidos',
        error: JSON.stringify(err),
      };
    }
    return {
      message: 'Erro desconhecido',
      error: true,
    };
  }
}

export async function changePassword(token, password) {
  return ApiPublic({
    token,
    password,
  }).post('/changepassword');
}

export async function forgotPassword(email) {
  return ApiPublic({
    email,
  }).post('/forgot');
}

export const validateStorageToken = () => {
  const token = getAuthStorageItem(Constants.TOKEN_KEY);
  if (!token) { return false; }
  return (token && jwtDecode(token).exp >= Date.now() / 1000);
};
