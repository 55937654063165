import React, { forwardRef } from 'react';
import { Select } from 'antd';

const { Option } = Select;

const SexoSelect = ({ size, value = null, onChange, placeholder, width }, ref) => (
  <span ref={ref}>
    <Select
      value={value}
      size={size}
      placeholder={placeholder}
      style={{ width: width || '100%' }}
      onChange={onChange}
    >
      <Option value="F">Feminino</Option>
      <Option value="M">Masculino</Option>
    </Select>
  </span>
);

export default forwardRef(SexoSelect);
