import React, { useState, useEffect, forwardRef } from 'react';

import { Select, Avatar } from 'antd';
import { useApi, useNotification } from '@core/hooks';
import Label from '../visual/Label';

const { Option } = Select;

const ClassificacaoSelect = ({ style = {}, width, ...props }, ref) => {
  const { api: Api } = useApi();
  const notification = useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data: items } = await Api.get('/classificacao');
        setData(items);
      } catch (err) {
        notification.error('Erro ao carregar Classificação');
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const widthStyle = { width: width || '100%' };
  return (
    <span ref={ref}>
      <Select
        {...props}
        showSearch
        loading={loading}
        optionFilterProp="label"
        style={{ ...widthStyle, ...style }}
        filterOption={(input, option) => option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {data.map((item) => (
          <Option key={item.id} value={item.id} label={item.nome}>
            <Label prefix={<Avatar size={8} style={{ background: item.cor || '#e3e3e3' }} />}>{item.nome}</Label>
          </Option>
        ))}
      </Select>
    </span>
  );
};

export default forwardRef(ClassificacaoSelect);
