import axios from 'axios';
import jwt from 'jsonwebtoken';
import * as Constants from '@core/constants';
import { makeSalt, cipher } from '@core/security';

const PublicApi = (data = {}) => {
  const salt = makeSalt(256);
  const encrypt = cipher(salt);

  const chave = encrypt(Constants.APP_KEY);
  const token = jwt.sign(data, chave);

  return axios.create({
    baseURL: Constants.API_BASE_URL,
    headers: {
      'x-security-hash': salt,
      'x-security-token': token,
    },
  });
};

export default function useApiPublic() {
  return {
    api: PublicApi,
  };
}
