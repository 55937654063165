import React, { useState, useEffect, forwardRef } from 'react';

import { Select } from 'antd';
import { useApi, useNotification } from '@core/hooks';
import { connect } from 'react-redux';

const { Option } = Select;

const FeaturesSelect = ({ size, mode = 'multiple', value, style = {}, allowClear = true, onChange, placeholder, width, disabled = false }, ref) => {
  const { api: Api } = useApi();
  const [data, setData] = useState([]);
  const notification = useNotification();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data: features } = await Api.get('/core/feature');
        setData(features);
      } catch (err) {
        console.error(err);
        notification.error('Erro ao tentar carregar as Features');
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const widthStyle = { width: width || '100%' };
  return (
    <span ref={ref}>
      <Select
        showSearch
        size={size}
        mode={mode}
        value={value}
        loading={loading}
        optionFilterProp="label"
        allowClear={allowClear}
        placeholder={placeholder}
        disabled={disabled}
        style={{ ...widthStyle, ...style }}
        onChange={onChange}
      >
        {data.map((item) => (
          <Option key={item.id} value={item.id} label={item.name}>{item.name}</Option>
        ))}
      </Select>
    </span>
  );
};

const mapStateToProps = (state) => ({
  authUser: state.auth.authUser,
});
export default connect(mapStateToProps, null)(forwardRef(FeaturesSelect));
