export const FETCH_ERROR = 'FETCH_ERROR';
export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';

export const fetchStart = () => ({
  type: FETCH_START,
});

export const fetchSuccess = () => ({
  type: FETCH_SUCCESS,
});

export const fetchError = (error) => ({
  type: FETCH_ERROR,
  payload: error,
});

export const showMessage = (message) => ({
  type: SHOW_MESSAGE,
  payload: message,
});

export const hideMessage = () => ({
  type: HIDE_MESSAGE,
});
