import { notification } from 'antd';

export default function useNotification() {
  const getNotification = (type, title, message, description) => (!!message && !description
    ? notification[type]({ message: title, description: message })
    : notification[type]({ message, description }));
  return {
    error: (message, description) => getNotification('error', 'Erro', message, description),
    info: (message, description) => getNotification('info', 'Informação', message, description),
    warn: (message, description) => getNotification('warn', 'Atenção', message, description),
    success: (message, description) => getNotification('success', 'Sucesso', message, description),
  };
}
