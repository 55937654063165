import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { Icon, Menu } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { sitemap } from './sitemap';

const MenuItemContainer = styled(Menu.Item)`
  align-items: center;
  border-left: 2px solid #003366;
  display: flex;
  padding-left: 24px;
  `;

const MenuItem = ({ collapsed, icon, text }) => {
  if (collapsed) return (<Icon type={icon} />);
  return (
    <>
      <Icon type={icon} />
      <span>{text}</span>
    </>
  );
};
const SubmenuItem = ({ collapsed, icon, text }) => {
  if (collapsed) return (<Icon type={icon} />);
  return (
    <span>
      <Icon type={icon} />
      <span>{text}</span>
    </span>
  );
};

export default function SidebarMenu({ collapsed }) {
  const history = useHistory();
  const location = useLocation();
  const [items, setItems] = useState();
  const { authUser } = useSelector((state) => state.auth);

  const navigate = (url) => {
    if (url) { history.push(url); }
  };

  const searchSelected = () => {
    const item = _(items)
      .thru((coll) => _.union(coll, _.map(coll, 'items') || []))
      .flatten()
      .find({ url: location.pathname });

    if (item && item.id) return [item.id];
    return [];
  };

  useEffect(() => {
    if (sitemap) {
      const menuItems = sitemap.filter((o) => o.roles.find((role) => role === authUser.roles || role === '*'));
      setItems(menuItems);
    }
  }, [sitemap]);

  return (
    <Menu theme="dark" defaultSelectedKeys={searchSelected()} mode="inline" style={{ display: 'flex', height: 'calc(100% - 147px)', flexDirection: 'column' }}>
      {items && items.filter((o) => o.align !== 'bottom').map((menu) => {
        if (menu.items && menu.items.length) {
          return (
            <Menu.SubMenu
              key={menu.id}
              title={<SubmenuItem collapsed={collapsed} icon={menu.icon} text={menu.title} />}
            >
              {menu.items.map((item) => (
                <MenuItemContainer key={item.id} onClick={() => navigate(item.url)} className="gx-pl-4">
                  <MenuItem collapsed={collapsed} icon={item.icon} text={item.title} />
                </MenuItemContainer>
              ))}
            </Menu.SubMenu>
          );
        }

        return (
          <MenuItemContainer key={menu.id} onClick={() => navigate(menu.url)}>
            <MenuItem key={menu.id} collapsed={collapsed} icon={menu.icon} text={menu.title} />
          </MenuItemContainer>
        );
      })}
      {items && items.find((o) => o.align === 'bottom') && (<div style={{ flex: '1 auto' }} />)}
      {items && items.filter((o) => o.align === 'bottom').map((menu) => {
        if (menu.items && menu.items.length) {
          return (
            <Menu.SubMenu
              key={menu.id}
              title={<SubmenuItem collapsed={collapsed} icon={menu.icon} text={menu.title} />}
            >
              {menu.items.map((item) => (
                <MenuItemContainer key={item.id} onClick={() => navigate(item.url)} className="gx-pl-4">
                  <MenuItem collapsed={collapsed} icon={item.icon} text={item.title} />
                </MenuItemContainer>
              ))}
            </Menu.SubMenu>
          );
        }

        return (
          <MenuItemContainer key={menu.id} onClick={() => navigate(menu.url)}>
            <MenuItem key={menu.id} collapsed={collapsed} icon={menu.icon} text={menu.title} />
          </MenuItemContainer>
        );
      })}
    </Menu>
  );
}
