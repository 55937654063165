import React, { forwardRef } from 'react';
import MaskedInput from 'antd-mask-input';

function CpfInput(props, ref) {
  return (
    <span ref={ref}>
      <MaskedInput
        maxLength={14}
        mask="111.111.111-11"
        style={{ width: props.width || '100%' }}
        {...props}
      />
    </span>
  );
}

export default forwardRef(CpfInput);
