import React, { forwardRef } from 'react'
import CurrencyInput from 'react-currency-input'

const DinheiroInput = (props, ref) => {
    return (
        <CurrencyInput ref={ref} decimalSeparator="," thousandSeparator="." prefix="R$ " className={`ant-input ${props.className}`} {...props} />
    )
}

export default forwardRef(DinheiroInput)
