import React from 'react';

import Spin from 'antd/lib/spin';

export default function Loading({ children, ...props }) {
  return (
    <Spin spinning={props.loading || false} size="large" delay={500}>
      {children}
    </Spin>
  );
}
