import axios from 'axios';
import { Modal } from 'antd';
import * as Constants from '@core/constants';
import { userSignOut } from '@core/redux/actions/Auth';
import { getAuthStorageItem } from '@core/security';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export default function useApi() {
  const history = useHistory();
  const dispatch = useDispatch();

  const modalError = (message) => {
    Modal.error({
      title: 'Erro',
      content: message,
    });
  };

  const Api = axios.create({
    baseURL: Constants.API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  Api.interceptors.request.use((config) => {
    const cfg = config;
    const token = getAuthStorageItem(Constants.TOKEN_KEY);

    if (token !== null) {
      cfg.headers.Authorization = `Bearer ${token}`;
    }

    return cfg;
  }, (err) => Promise.reject(err));

  Api.interceptors.response.use((response) => response, (error) => {
    // eslint-disable-next-line prefer-promise-reject-errors
    if (!error) return Promise.reject({ message: 'Erro desconhecido' });
    if (error && !error.response) return Promise.reject(error);

    if (error.response.status) {
      if (error.response.status === 401) {
        modalError('Usuário não autorizado, refaça o login');
        dispatch(userSignOut());
        history.push('/entrar');
      }
      if (error.response.status === 403) {
        history.push('/acessonegado');
      }
    }
    return Promise.reject(error.response);
  });

  const saveData = (endpoint, data) => {
    if (data.id && data.id > 0) {
      const record = { ...data };
      delete record.id;

      return Api.put(`${endpoint}/${data.id}`, record);
    }
    return Api.post(endpoint, data);
  };

  const saveConfig = (key, data) => {
    if (key && data) {
      return Api.post(`core/config/key/${key}`, {
        ...data,
        key,
      });
    }
  };

  const getConfig = (key) => {
    if (key) {
      return Api.get(`core/config/key/${key}`);
    }
  };

  const removeData = (endpoint, key) => Api.delete(`${endpoint}/${key}`);

  return {
    api: Api,
    saveData,
    getConfig,
    saveConfig,
    removeData,
  };
}
