import React from 'react';
import styled from 'styled-components';

const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  padding: 0 8px;
  & > span {
    color: ${(props) => (props.labelColor ? props.labelColor : '#395a7a')};
    font-size: 14px;
    margin-bottom: 4px;
    font-weight: ${(props) => (props.bold ? '700' : 'normal')};
  }
  & > label.field-error{
    color: red;
    font-size: 12px;
    font-style: italic;
    padding-left: 8px;
  }
`;
function FieldControl({ label, bold, labelColor, children, showError, errorMessage = 'Por favor, preencha o campo', ...props }) {
  return (
    <Field labelColor={labelColor} bold={bold} {...props}>
      <span>{label}</span>
      {children}
      {showError && (<span className="field-error">{errorMessage}</span>)}
    </Field>
  );
}

export default FieldControl;
