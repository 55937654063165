import { combineReducers } from 'redux';

import Auth from './Auth';
import Sessao from './Sessao';
import Common from './Common';


const reducers = combineReducers({
  auth: Auth,
  sessao: Sessao,
  common: Common,
});

export default reducers;
