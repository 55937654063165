import React, { useState, forwardRef, useEffect } from 'react';

import { useApi, useNotification } from '@core/hooks';
import { Icon, Input, Tooltip } from 'antd';

const EmailInput = ({ onCheck, onStatusChange, ...props }, ref) => {
  const { api: Api } = useApi();
  const [enter, setEnter] = useState();
  const [status, setStatus] = useState();
  const notification = useNotification();

  useEffect(() => {
    if (status && onStatusChange) {
      onStatusChange(status);
    }
  }, [status]);

  const handleBlur = async (e) => {
    try {
      const email = e.target.value;
      if (email && enter !== email.toLowerCase()) {
        setStatus('validating');

        const { data: res } = await Api.get(`/validate/user?email=${email}`);
        if (res) {
          setStatus('success');
          if (onCheck) { onCheck(true); }
        } else {
          setStatus('error');
          if (onCheck) { onCheck(false); }
        }
      }
      if (!email) { setStatus(); }
    } catch (err) {
      setStatus('error');
      notification.error('Erro ao tentar verificar se o e-mail existe');
      console.error(err);
    }
  };

  const handleFocus = async (e) => {
    setEnter(e.target.value.toLowerCase());
  };

  return (
    <span ref={ref}>
      <Input
        type="email"
        {...props}
        suffix={(
          <>
            {status === 'success' && <Tooltip title="E-mail válido"><Icon type="check-circle" style={{ color: 'green' }} /></Tooltip>}
            {status === 'error' && <Tooltip title="E-mail já em uso"><Icon type="close-circle" style={{ color: 'red' }} /></Tooltip>}
            {status === 'validating' && <Tooltip title="Validando..."><Icon type="loading" /></Tooltip>}
          </>
        )}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    </span>
  );
};

export default forwardRef(EmailInput);
