import React, { useState, useEffect, forwardRef } from 'react';

import { useApi, useNotification } from '@core/hooks';
import Select from 'antd/lib/select';

const { Option } = Select;

const SegmentosSelect = ({ style = {}, width, ...props }, ref) => {
  const { api: Api } = useApi();
  const [data, setData] = useState([]);
  const notification = useNotification();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data: items } = await Api.get('/segmento');
        setData(items);
      } catch (err) {
        console.error(err);
        notification.error('Erro ao tentar carregar os segmentos');
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const widthStyle = { width: width || '100%' };
  return (
    <span ref={ref}>
      <Select
        {...props}
        showSearch
        loading={loading}
        optionFilterProp="label"
        style={{ ...widthStyle, ...style }}
        filterOption={(input, option) => option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {data.map((item) => (
          <Option key={item.id} value={item.id} label={item.nome}>{item.nome}</Option>
        ))}
      </Select>
    </span>
  );
};

export default forwardRef(SegmentosSelect);
