const defineUrlApi = () => {
  const { hostname } = window.location;

  // DUCASH PRODUCTION ENVIRONMENT
  if (hostname === 'sysducash.ducash.com.br') { return 'https://api-sysducash.ducash.com.br/api/v1'; }

  // DEVELOPER ENVIRONMENT
  return 'http://localhost:5501/api/v1';
};

const defineUrlApiDucash = () => {
  const { hostname } = window.location;
  return 'https://contratoapi.grupozelo.com'
  // DUCASH PRODUCTION ENVIRONMENT
  if (hostname === 'sysducash.ducash.com.br') { return 'https://contratoapi.grupozelo.com'; }

  // DEVELOPER ENVIRONMENT
  return 'https://apidev.grupozelo.com';
};

export const HOME_PAGE = '/inicio';
export const API_BASE_URL = defineUrlApi();
export const API_DUCASH_BASE_URL = defineUrlApiDucash();
export const APP_KEY = 'HVVXRNLC7PyI4RAEDaSQwwVXvzardI6S';
export const LOGOS = '';
