import {
  SIGNUP_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  GET_USER_DATA,
  USER_TOKEN_SET,
  PUT_USER_PERFIL,
  SHOW_AUTH_LOADER,
  HIDE_AUTH_LOADER,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
} from '../actions/Auth';

const INIT_STATE = {
  alertMessage: '',
  showMessage: false,
  loader: false,
  token: null,
  authUser: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      };
    }
    case SIGNUP_USER: {
      return {
        ...state,
        authUser: action.payload,
      };
    }
    case PUT_USER_PERFIL: {
      return {
        ...state,
        authUser: action.payload,
      };
    }
    case SIGNIN_USER: {
      return {
        ...state,
        authUser: action.payload,
      };
    }
    case GET_USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }
    case SIGNOUT_USER: {
      return {
        ...state,
        authUser: null,
        token: null,
        loader: false,
      };
    }

    case SHOW_AUTH_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
      };
    }
    case HIDE_AUTH_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
      };
    }
    case SHOW_AUTH_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case HIDE_AUTH_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    default:
      return state;
  }
};
