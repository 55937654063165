import React from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const TopContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0px;
`;

const BaseContainer = styled.div`
  width: 100%;
`;

const Home = loadable(() => import('./home'));
const Grupo = loadable(() => import('./grupo'));
const Logos = loadable(() => import('./logos'));
const Perfil = loadable(() => import('./perfil'));
const Empresa = loadable(() => import('./empresa'));
const Validar = loadable(() => import('./validar'));
const Usuario = loadable(() => import('./usuario'));
const Relatorios = loadable(() => import('./relatorios'));
const NotFound = loadable(() => import('./errors/NotFound'));
const AlterarSenha = loadable(() => import('./auth/ForceChange'));
const AccessDenied = loadable(() => import('./errors/AccessDenied'));

export default function App() {
  const { authUser } = useSelector((state) => state.auth);

  if (authUser && !authUser.force_change) {
    return (
      <TopContainer>
        <BaseContainer>
          <Switch>
            <Route path="/inicio">
              <Home />
            </Route>
            <Route path="/perfil">
              <Perfil />
            </Route>
            <Route path="/grupos">
              <Grupo />
            </Route>
            <Route path="/usuarios">
              <Usuario />
            </Route>
            <Route path="/empresas">
              <Empresa />
            </Route>
            <Route path="/logos">
              <Logos />
            </Route>
            <Route path="/validar">
              <Validar />
            </Route>
            <Route path="/relatorios">
              <Relatorios />
            </Route>

            <Route path="/acesso-negado">
              <AccessDenied />
            </Route>
            <Route path="/">
              <Home />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </BaseContainer>
      </TopContainer>
    );
  }

  return (
    <Switch>
      <Route exact path="/alterar"><AlterarSenha /></Route>
    </Switch>
  );
}
