import React, { forwardRef } from 'react';
import moment from 'moment';
import { Tooltip } from 'antd';
import * as Formatar from '@core/utils/Formatar';


const Duration = (props, ref) => {
    const date = moment(props.date).format('DD MMM YYYY, HH:mm');
    const duration = Formatar.Duracao(props.date);
    return (
        <Tooltip ref={ref} title={date}>
            {props.size === 'small' && (<small>{duration}</small>)}
            {props.size !== 'small' && (duration)}
        </Tooltip>
    );
};

export default forwardRef(Duration);
