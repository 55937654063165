import React, { useState, useEffect, forwardRef } from 'react';
import * as Formatar from '@core/utils/Formatar';
import InputMask from 'react-input-mask'; // 'antd-mask-input'

const TelefoneInput = (props, ref) => {
  const [mask, setMask] = useState('(99) 99999-9999');

  const defineMask = (value) => {
    const phone = Formatar.Limpar(value);
    if (/(^[0-9]{2}[9]{1})/.test(phone)) setMask('(99) 99999-9999');
    else setMask('(99) 9999-9999');
  };

  const handlePaste = (e) => {
    e.preventDefault();
    defineMask(e.clipboardData.getData('Text'));
    props.onChange({
      ...e,
      target: {
        ...e.target,
        value: Formatar.Telefone(e.clipboardData.getData('Text')),
      },
    });
  };

  const handleChange = (e) => {
    defineMask(e.target.value);
    props.onChange(e);
  };


  useEffect(() => {
    defineMask(props.value);
  }, [props.value]);

  return (
    <InputMask
      {...props}
      ref={ref}
      mask={mask}
      maskChar="_"
      onPaste={handlePaste}
      onChange={handleChange}
      className={`ant-input ${props.className}`}
    />
  );
};

export default forwardRef(TelefoneInput);
