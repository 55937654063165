import React, { useState, forwardRef } from 'react';

import { useApi, useNotification } from '@core/hooks';
import debounce from 'lodash/debounce';
import { Select, Empty, Avatar } from 'antd';
import { Flexbox } from '..';
import Label from '../visual/Label';

const { Option } = Select;

function UsuariosLazy({ className, value, ...props }, ref) {
  const { api: Api } = useApi();
  const [data, setData] = useState();
  const notification = useNotification();
  const [loading, setLoading] = useState(false);
  const [firstSearch, setFirstSearch] = useState(false);

  const loadData = async (term) => {
    if (term && term.length > 2) {
      setLoading(true);
      try {
        const { data: res } = await Api.get(`/core/search/user?term=${term}`);
        setData(res);
        setFirstSearch(true);
      } catch (err) {
        console.error(err);
        notification.error('Erro ao tentar encontrar os usuários');
      } finally {
        setLoading(false);
      }
    }
  };

  const searchData = debounce(loadData, 800);
  return (
    <span ref={ref}>
      <Select
        {...props}
        showSearch
        labelInValue
        value={value}
        loading={loading}
        showArrow={false}
        filterOption={false}
        onSearch={searchData}
        defaultActiveFirstOption
        className={`gx-w-100 ${className || ''}`}
        notFoundContent={!firstSearch && !data ? 'Digite para pesquisar...' : (
          <Empty
            className="gx-m-4" description={
              <small>Nenhum usuário encontrado.</small>
            }
          />
        )}
        {...props}
      >
        {value && (!data || !data.length) && (
          <Option key={value.key} value={value.key} label={value.label}>{value.label}</Option>
        )}
        {data && data.map((item) => (
          <Option key={item.id} value={item.id} label={item.display_name}>
            <Flexbox flexDirection="row" alignItems="center">
              {item.photo_url && (<Avatar size={24} src={item.photo_url} />)}
              {!item.photo_url && (<Avatar size={24} icon="user" />)}
              <Label className="gx-ml-2">{item.display_name || item.username}</Label>
            </Flexbox>
          </Option>
        ))}
      </Select>
    </span>
  );
}

export default forwardRef(UsuariosLazy);
