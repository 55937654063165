import React from 'react';

import { cpf, cnpj } from 'cpf-cnpj-validator';
import PasswordValidator from 'password-validator';
import * as Formatar from './Formatar';

export function Cpf(rule, value, cb) {
  if (value && cpf.isValid(value)) {
    if (cb) cb();
    else return true;
  }
  if (rule && !rule.required && !value) {
    if (cb) cb();
    else return true;
  }

  if (cb) cb(true);
  else return false;
}

export function Cnpj(rule, value, cb) {
  if (value && cnpj.isValid(value)) {
    if (cb) cb();
    else return true;
  }
  if (rule && !rule.required && !value) {
    if (cb) cb();
    else return true;
  }

  if (cb) cb(true);
  else return false;
}

export function Telefone(rule, value, cb) {
  if (value) {
    const tel = Formatar.Limpar(value);

    if (rule.required || (!rule.required && tel !== '')) {
      const min = tel.length >= 3 && (tel[2] === '9' || tel[2] === '8') ? 11 : 10;
      if (tel.length >= min) { cb(); }
    } else {
      cb();
    }
  }

  if (!value && !rule.required) { cb(); }

  cb(true);
}

export function Inteiro(valor) {
  try {
    const result = parseInt(valor);
    return !!result;
  } catch {
    return false;
  }
}

export function Senha(rule, value, cb) {
  const schema = new PasswordValidator();
  schema
    .is().min(6)
    .is().max(20)
    // eslint-disable-next-line newline-per-chained-call
    .has().digits(1)
    // eslint-disable-next-line newline-per-chained-call
    .has().letters(1)
    // eslint-disable-next-line newline-per-chained-call
    .has().not().spaces();

  if (value) {
    const errors = schema.validate(value, { list: true });
    console.log(errors);
    if (errors && errors.length) {
      cb(
        <ul>
          {errors.find((o) => o === 'digits') && (<li><small>Deve conter pelo menos um número</small></li>)}
          {errors.find((o) => o === 'spaces') && (<li><small>Não pode conter espaço em branco</small></li>)}
          {errors.find((o) => o === 'letters') && (<li><small>Deve conter pelo menos uma letra</small></li>)}
          {errors.find((o) => o === 'min') && (<li><small>Mínimo de 8 caracteres (números ou letras)</small></li>)}
          {errors.find((o) => o === 'max') && (<li><small>Máximo de 20 caracteres (números ou letras)</small></li>)}
        </ul>,
      );
    }
  } else if (rule.required) {
    cb(<small>Este campo é obrigatório</small>);
  }

  cb();
}

export function NomeSobrenome(rule, value, cb) {
  if (value) {
    const nomeSobrenome = value.trim().split(' ');
    if (nomeSobrenome.length >= 2) {
      for (let i = 0; i < nomeSobrenome.length; i++) {
        const item = nomeSobrenome[i].replace(/ /g, '');
        if (item.length === 0) {
          cb(true); // error
          return;
        }
      }
      cb();
    }
  }

  cb(true); // error
}
