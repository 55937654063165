import React, { forwardRef } from 'react'
import MaskedInput from 'antd-mask-input'

const InputMask = ({ value, width, onChange, mask }, ref) => {
    return (
        <span ref={ref}>
            <MaskedInput
                value={value}
                maxLength={18}
                mask={mask}
                onChange={onChange}
                style={{ width: width || '100%' }}
            />
        </span>
    );
}

export default forwardRef(InputMask)