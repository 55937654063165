import React, { useEffect, useState, forwardRef } from 'react';

import { useApi, useNotification } from '@core/hooks';
import { Icon, Input, Tooltip } from 'antd';

export const validator = (status) => (rule, value, cb) => {
  console.log('validator called')
  if (rule.required || (!rule.required && status !== '')) {
    if (status !== 'error') { cb(); }
  }

  // else {
  //   cb();
  // }

  cb(true);
};

export default forwardRef(({ onStatusChange, ...props }, ref) => {
  const { api: Api } = useApi();
  const [enter, setEnter] = useState();
  const [status, setStatus] = useState();
  const notification = useNotification();

  const validate = async (e) => {
    const username = e.target.value;
    if (username && enter !== username.toLowerCase()) {
      setStatus('validating');
      try {
        const { data: res } = await Api.get(`validate/user?username=${username}`);
        if (res && !res.exists) {
          setStatus('success');
        } else {
          setStatus('error');
        }
      } catch (err) {
        console.error(err);
        notification.error('Erro ao tentar verificar se o nome de usuário existe');
        setStatus('error');
      }
    }
    if (!username) { setStatus(); }
  };

  const handleFocus = async (e) => {
    setEnter(e.target.value.toLowerCase());
  };

  useEffect(() => {
    if (status && onStatusChange) {
      onStatusChange(status);
    }
  }, [status]);

  return (
    <span ref={ref}>
      <Input
        {...props}
        suffix={(
          <>
            {status === 'success' && <Tooltip title="Login válido"><Icon type="check-circle" style={{ color: 'green' }} /></Tooltip>}
            {status === 'error' && <Tooltip title="Login já em uso"><Icon type="close-circle" style={{ color: 'red' }} /></Tooltip>}
            {status === 'validating' && <Tooltip title="Validando..."><Icon type="loading" /></Tooltip>}
          </>
        )}
        onBlur={validate}
        onFocus={handleFocus}
        onPressEnter={validate}
      />
    </span>
  );
});
