import React from 'react';
import { Select } from 'antd';

import Roles from '@core/constants/Roles';

const { Option } = Select;

const PapeisSelect = React.forwardRef((props, ref) => (
  <Select
    ref={ref}
    style={{ width: props.width || '100%' }}
    {...props}
  >
    <Option value={Roles.Administrador}>{Roles[Roles.Administrador]}</Option>
    <Option value={Roles.Parceiro}>{Roles[Roles.Parceiro]}</Option>
    <Option value={Roles.Gestor}>{Roles[Roles.Gestor]}</Option>
    <Option value={Roles.Usuario}>{Roles[Roles.Usuario]}</Option>
  </Select>
));

export default PapeisSelect;
