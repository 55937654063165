export const themes = {
  default: {
    cor: 'inherits',
    fundo: 'inherits',
  },
  roxo: {
    cor: '#5d31b1',
    fundo: '#e9e1f0',
  },
  verde: {
    cor: '#44c2a3',
    fundo: '#ddfde1',
  },
  azul: {
    cor: '#395a7a',
    fundo: '#e6f7ff',
  },
  branco: {
    cor: '#fff',
    fundo: 'transparent',
  },
  cinza: {
    cor: '#949494',
    fundo: '#e0e0e0',
  },
  preto: {
    cor: '#000',
    fundo: '#fff',
  },
  vermelho: {
    cor: '#f43e61',
    fundo: 'transparent',
  },
  verde_escuro: {
    cor: '#4ab858',
    fundo: 'transparent',
  },
};
