import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';

import { getAuthStorageItem } from '@core/security';
import { validateStorageToken } from '@core/services/local/Auth';
import { loadUserAndTokenFromStorage } from '@core/redux/actions';

import * as Constants from '@core/constants';

export default function RestrictedRoute({ children, token, ...rest }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const handleRender = () => {
    if (!token) {
      if (validateStorageToken()) {
        const storageUser = getAuthStorageItem(Constants.USER_KEY);
        const storageToken = getAuthStorageItem(Constants.TOKEN_KEY);
        dispatch(loadUserAndTokenFromStorage(JSON.parse(storageUser), storageToken));

        return (children);
      }
      return (
        <>
          <Redirect
            to={{
              pathname: '/entrar',
              state: { from: location },
            }}
          />
        </>
      );
    }
    return (children);
  };
  return (
    <Route
      {...rest}
      render={handleRender}
    />
  );
}
