const todos = ['*'];
const validacao = ['partner'];
const interno = ['admin', 'staff', 'user'];

export const sitemap = [
  {
    id: '0',
    icon: 'home',
    title: 'Início',
    url: '/inicio',
    roles: [...todos],
  },

  {
    id: '2',
    icon: 'shop',
    title: 'Empresas',
    url: '/empresas',
    roles: [...interno],
  },
  {
    id: '3',
    icon: 'picture',
    title: 'Logos',
    url: '/logos',
    roles: ['admin', 'staff'],
  },
  {
    id: '4',
    icon: 'team',
    title: 'Grupos',
    url: '/grupos',
    roles: ['admin'],
  },
  {
    id: '5',
    icon: 'user',
    title: 'Usuários',
    url: '/usuarios',
    roles: ['admin'],
  },
  {
    id: '6',
    icon: 'pic-left',
    title: 'Relatórios',
    roles: [...interno],
    items: [{
      id: '6.1',
      icon: 'database',
      title: 'Bilhetagem',
      url: '/relatorios/bilhetagem',
      roles: [...interno],
    }, {
      id: '6.2',
      icon: 'shop',
      title: 'Empresas',
      url: '/relatorios/empresa',
      roles: [...interno],
    }],
  },
  {
    id: '7',
    icon: 'carry-out',
    title: 'Validação',
    url: '/validar',
    roles: [...validacao],
  },
  {
    id: '99',
    align: 'bottom',
    icon: 'setting',
    title: 'Configurações',
    url: '/configuracao',
    roles: [],
  },
];
