// import './ReactotronConfig'
import React from 'react';
import ReactDOM from 'react-dom';

import { AppContainer } from 'react-hot-loader';
import * as Sentry from '@sentry/browser';
import NextApp from './NextApp';
// import registerServiceWorker from './registerServiceWorker';
import {unregister} from './registerServiceWorker';

// Enable Sentry
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
  Sentry.init({
    dsn: 'https://360b55d79c40408ea2c39f6de1d1ca8d@sentry.io/1758931',
    environment: process.env.NODE_ENV,
  });
}
// Wrap the rendering in a function:
const render = (Component) => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <Component />
    </AppContainer>,
    document.getElementById('root'),
  );
};

// Do this once
unregister();

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./NextApp', () => {
    render(NextApp);
  });
}
