
import React from 'react';

import { ConfigProvider } from 'antd';

// Configurando locale do moment
import ptBR from 'antd/es/locale/pt_BR';

import MainApp from './MainApp';

export default function App() {
  return (
    <ConfigProvider locale={ptBR}>
      <MainApp />
    </ConfigProvider>
  );
}


// import React from 'react';
// import { connect } from 'react-redux';
// import { Redirect, Switch, useLocation, useRouteMatch } from 'react-router-dom';
// import { ConfigProvider } from 'antd';

// import RestrictedRoute from '@core/components/security/RestrictedRoute_old';

// // Configurando locale do moment
// import ptBR from 'antd/es/locale/pt_BR';
// import moment from 'moment';
// import 'moment/locale/pt-br';
// import { getAuthStorageItem } from '@core/security';
// import * as Constants from '@core/constants';
// import { validateStorageToken } from '@core/services/local/Auth';
// import { loadUserAndTokenFromStorage } from '@core/redux/actions';
// import MainApp from './MainApp';

// moment.locale('pt-br');

// function App({ authUser, token, ...props }) {
//   const location = useLocation();
//   const { url } = useRouteMatch();

//   if (!token) {
//     if (validateStorageToken()) {
//       const storageUser = getAuthStorageItem(Constants.USER_KEY);
//       const storageToken = getAuthStorageItem(Constants.TOKEN_KEY);

//       props.loadUserAndTokenFromStorage(JSON.parse(storageUser), storageToken);

//       const dest = location.pathname === '/' ? Constants.HOME_PAGE : location.pathname;
//       return (<Redirect to={dest} />);
//     }
//     return (<Redirect to="/login" />);
//   }
//   if (location.pathname === '/') {
//     return <Redirect to={Constants.HOME_PAGE} />;
//   }

//   return (
//     <ConfigProvider locale={ptBR}>
//       <Switch>
//         <RestrictedRoute
//           path={url} authUser={authUser} token={token}
//           component={MainApp}
//         />
//       </Switch>
//     </ConfigProvider>
//   );
// }

// const mapStateToProps = ({ auth }) => {
//   const { authUser, token } = auth;
//   return { authUser, token };
// };

// export default connect(mapStateToProps, { loadUserAndTokenFromStorage })(App);
