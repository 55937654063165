import React, { useState, forwardRef, useEffect } from 'react';

import { Icon, Upload, message, notification } from 'antd';
import * as Constants from '@core/constants';
import { getAuthStorageItem } from '@core/security';

import './index.css';
import Loading from '@core/components/visual/Loading';

export default forwardRef(({ text, action, urlImage, onChange, ...props }, ref) => {
  const [error, setError] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('São permitidos apenas arquivos JPG ou PNG.');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('A imagem precisar ter menos de 2mb');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setError(false);
      setLoading(true);
      return;
    }
    if (info.file.status === 'error') {
      setError(true);
      setLoading(false);
      notification.error({
        message: 'Erro',
        description:
          'Não foi possível atualizar a foto de perfil. Tente novamente mais tarde.',
      });
    }
    if (info.file.status === 'done') {
      const { response } = info.file;
      if (response) {
        setError(response.success);
        setImageUrl(response.url);
        setLoading(false);
        if (onChange) {
          onChange(response.url);
        }
      }
    }
  };

  useEffect(() => {
    if (urlImage && !imageUrl) { setImageUrl(urlImage); }
  }, [urlImage]);

  const uploadButton = (
    <div>
      <Icon type={loading ? 'loading' : (error ? 'exclamation-circle' : 'plus')} />

      {text && (<div className="ant-upload-text">{text}</div>)}
      {!text && error && (<div className="ant-upload-text">Falhou</div>)}
      {!text && !error && (<div className="ant-upload-text">Carregar</div>)}
    </div>

  );

  return (
    <div ref={ref}>
      <Upload
        {...props}
        action={action}
        name="file_upload"
        showUploadList={false}
        listType="picture-card"
        className="avatar-uploader"
        headers={{
          authorization: `Bearer ${getAuthStorageItem(Constants.TOKEN_KEY)}`,
        }}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        {...props}
      >
        <Loading loading={loading}>
          {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
        </Loading>
      </Upload>
    </div>
  );
});
