import React from 'react';
import styled from 'styled-components';
import { themes } from '@core/constants/Themes';
import Cores from '@core/constants/Cores';

const LabelContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-weight: ${(props) => (props.bold ? 700 : 'normal')};
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'normal')};
  width: ${(props) => (props.block ? '100%' : 'inherits')};
  ${(props) => {
    if (props.bordered) {
      return `
        padding: 16px 8px;
        background: ${themes[props.theme].fundo};
        border-left: 3px solid ${themes[props.theme].cor};
      `;
    }
  }}
`;

export default function Label({ children, prefix, extra, html, className, theme = 'default', ...props }) {
  const style = {
    width: props.block ? '100%' : 'inherits',
    fontSize: props.fontSize ? props.fontSize : 16,
    textAlign: props.center ? 'center' : 'inherits',
    color: props.color
      ? props.color
      : (theme && !!themes[theme])
        ? themes[theme].cor
        : Cores.primaria,
  };
  return (
    <LabelContainer theme={theme} {...props}>
      {prefix && (
        <div className="gx-mr-1">{prefix}</div>
      )}
      {!html && (
        <div style={{ ...style }} className={className}>{children}</div>
      )}
      {!!html && (
        <div style={{ ...style }} className={className}><div dangerouslySetInnerHTML={{ __html: html }} /></div>
      )}
      {extra && (
        <div className="gx-ml-2">{extra}</div>
      )}
    </LabelContainer>
  );
}
