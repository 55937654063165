import _ from 'lodash';
import Roles from '@core/constants/Roles';
import * as Constants from '@core/constants';

export const checkFeatures = (authUser, features) => {
  const { groups } = authUser;

  let found = false;
  _.forEach(groups, (group) => {
    _.forEach(group.features, (feature) => {
      if (_.find(features, (o) => o === feature.abbrev.toLowerCase())) {
        found = true;
      }
    });

    // eslint-disable-next-line no-useless-return
    if (found) return;
  });

  return found;
};

export const isLocalhost = () => {
  const environment = window.location.hostname;

  return (environment.toLowerCase().includes('localhost'));
};

export const isAdmin = (authUser) => authUser.roles === Roles.Administrador;

export const isCoordenador = (authUser) => authUser.roles === Roles.Coordenador;

export const isUser = (authUser) => authUser.roles === Roles.Usuario;

export const makeSalt = (length = 32) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!#$%|';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const cipher = (salt) => {
  const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
  const byteHex = (n) => (`0${Number(n).toString(16)}`).substr(-2);
  // eslint-disable-next-line no-bitwise
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

  return (text) => text.split('')
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join('');
};

export const decipher = (salt) => {
  const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
  // eslint-disable-next-line no-bitwise
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
  return (encoded) => encoded.match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode) => String.fromCharCode(charCode))
    .join('');
};

export const setAuthStorageItem = (key, value) => {
  const encrypt = cipher(Constants.GLOBAL_SALT);
  localStorage.setItem(key, encrypt(value));
};

export const getAuthStorageItem = (key) => {
  const value = localStorage.getItem(key);

  if (!value) { return null; }

  const decrypt = decipher(Constants.GLOBAL_SALT);
  return decrypt(value);
};
