// import { SIGNOUT_USER } from '@core/redux/actions';
// import { validateStorageToken } from './Auth';

export const checkTokenExpirationMiddleware = (store) => (next) => (action) => {
  // if (!validateStorageToken()) {
  //   store.dispatch({ type: SIGNOUT_USER });
  //   localStorage.clear();
  // }
  next(action);
};
