import React, { forwardRef } from 'react'
import MaskedInput from 'antd-mask-input'

const Cnpj = (props, ref) => {
    return (
        <span ref={ref}>
            <MaskedInput
                maxLength={18}
                mask="11.111.111/1111-11"
                style={{ width: props.width || '100%' }}
                {...props}
            />
        </span>
    );
}

export default forwardRef(Cnpj)