import React, { useState, useEffect, forwardRef } from 'react';

import { useApi, useNotification } from '@core/hooks';
import Select from 'antd/lib/select';

const { Option } = Select;

const EmpresasSelect = ({ mode = 'default', allowClear, size, value, style = {}, onChange, placeholder, width, maxTagCount = 0 }, ref) => {
  const { api: Api } = useApi();
  const [data, setData] = useState([]);
  const notification = useNotification();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data: items } = await Api.get('/empresa');
        setData(items);
      } catch (err) {
        console.error(err);
        notification.error('Erro ao tentar carregar as empresas');
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const widthStyle = { width: width || '100%' };
  return (
    <span ref={ref}>
      <Select
        allowClear={allowClear}
        size={size}
        value={value}
        loading={loading}
        placeholder={placeholder}
        style={{ ...widthStyle, ...style }}
        mode={mode}
        maxTagCount={maxTagCount}
        showSearch
        optionFilterProp="label"
        filterOption={(input, option) => option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={onChange}
      >
        {data.map((item) => (
          <Option key={item.id} value={item.id} label={item.nome_fantasia}>{item.nome_fantasia}</Option>
        ))}
      </Select>
    </span>
  );
};

export default forwardRef(EmpresasSelect);
