export const SESSAO_LOJA = 'SESSAO LOJA';
export const SESSAO_EMPRESA = 'SESSAO EMPRESA';

// ##############################################
// ## DADOS DA SESSÃO DO USUÁRIO
// ##############################################
export const putSessaoEmpresa = (value) => ({
  type: SESSAO_EMPRESA,
  payload: value,
});

export const putSessaoLoja = (value) => ({
  type: SESSAO_LOJA,
  payload: value,
});
