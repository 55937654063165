import React, { useState, forwardRef } from 'react';

import { Ufs } from '@core/constants/Ufs';
import MaskedInput from 'antd-mask-input';
import { Button, Input, message } from 'antd';
import * as Formatar from '@core/utils/Formatar';

const InputGroup = Input.Group;

const CepInput = ({ value, width, onChange, onSearch, searchable = false, autoSearch = false, disabled = false }, ref) => {
  const [loading, setLoading] = useState(false);

  const inputRef = React.createRef();

  const handleClick = async (e) => {
    if (e) e.preventDefault();
    const cep = Formatar.Limpar(inputRef.current.props.value);
    if (cep && cep !== '') {
      setLoading(true);
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const retorno = await response.json();

      if (retorno && onSearch) {
        if (retorno.erro) message.warning(`CEP ${cep} não encontrado.`);
        else {
          const retornoCep = { ...retorno };
          retornoCep.uf = Ufs[retorno.uf.toUpperCase()];
          onSearch(retornoCep);
        }
      }
      setLoading(false);
    }
  };

  return (
    <span ref={ref}>
      <InputGroup compact>
        <MaskedInput
          ref={inputRef}
          onBlur={autoSearch ? handleClick : null}
          mask="11111-111"
          value={value}
          maxLength={9}
          onChange={onChange}
          disabled={disabled}
          onPressEnter={handleClick}
          style={{ width: width || '100%' }}
        />
        {searchable && (<Button icon="search" loading={loading} disabled={disabled} onClick={handleClick} />)}
      </InputGroup>
    </span>
  );
};

export default forwardRef(CepInput);
