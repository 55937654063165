import * as Constants from '@core/constants/Auth';
import { signInWithEmailAndPassword } from '@core/services/local/Auth';
import { setAuthStorageItem } from '@core/security';
import axios from 'axios';
import * as LocalStorageConsts from '@core/constants/LocalStorageConsts';

// Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const GET_USER_DATA = 'GET_USER_DATA';
export const USER_TOKEN_SET = 'USER_TOKEN_SET';
export const PUT_USER_PERFIL = 'PUT_USER_PERFIL';
export const SHOW_AUTH_LOADER = 'SHOW_AUTH_LOADER';
export const HIDE_AUTH_LOADER = 'HIDE_AUTH_LOADER';
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE';
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE';

// ##############################################
// ## LOGIN DO USUÁRIO
// ##############################################
const setUserSignIn = (user) => ({
  type: SIGNIN_USER,
  payload: user,
});
const setUserToken = (token) => ({
  type: USER_TOKEN_SET,
  payload: token,
});
export const showAuthLoader = () => ({
  type: SHOW_AUTH_LOADER,
});
export const hideAuthLoader = () => ({
  type: HIDE_AUTH_LOADER,
});
export const showAuthMessage = (message) => ({
  type: SHOW_AUTH_MESSAGE,
  payload: message,
});
export const hideAuthMessage = () => ({
  type: HIDE_AUTH_MESSAGE,
});

// ATUALIZAÇÃO DO PERFIL
export const putUserPerfil = (user) => ({
  type: PUT_USER_PERFIL,
  payload: user,
});

export const userSignIn = (user) => async (dispatch) => {
  dispatch(hideAuthMessage());
  dispatch(showAuthLoader());
  // faz o login do usuário
  const userAuth = await signInWithEmailAndPassword(user.username, user.password);
  if (userAuth && !userAuth.error) {
    // Armazena token no localstorage
    setAuthStorageItem(Constants.USER_KEY, JSON.stringify(userAuth.user));
    setAuthStorageItem(Constants.TOKEN_KEY, userAuth.access_token.token);
    dispatch(setUserSignIn(userAuth.user));
    dispatch(setUserToken(userAuth.access_token.token));
    dispatch(hideAuthMessage());
    dispatch(hideAuthLoader());
  } else {
    if (!userAuth) {
      dispatch(showAuthMessage('Serviço indisponível. Tente novamente mais tarde.'));
    }
    if (userAuth.error) {
      const { error } = userAuth;
      if (error.data) {
        if (error.data.includes('E_USER_NOT_FOUND') || error.data.includes('E_PASSWORD_MISMATCH')) {
          dispatch(showAuthMessage('Usuário e senha inválidos.'));
        } else if (error.data.includes('E_USER_INACTIVE')) {
          dispatch(showAuthMessage('Usuário inativo.'));
        }
      } else {
        dispatch(showAuthMessage('Erro no Serviço de Autenticação. Tente novamente mais tarde.'));
      }
    }
  }
  dispatch(hideAuthLoader());
};

export const loadUserAndTokenFromStorage = (user, token) => (dispatch) => {
  dispatch(setUserSignIn(user));
  dispatch(setUserToken(token));
};

export const userSignOut = () => (dispatch) => {
  const lembrar = localStorage.getItem(LocalStorageConsts.LembrarLogin);
  const collapse = localStorage.getItem(LocalStorageConsts.SidebarCollapsed);

  localStorage.clear();
  if (lembrar) {
    localStorage.setItem(LocalStorageConsts.LembrarLogin, lembrar);
    localStorage.setItem(LocalStorageConsts.SidebarCollapsed, collapse);
  }
  delete axios.defaults.headers.common.Authorization;
  dispatch({
    type: SIGNOUT_USER,
  });
};

// ##############################################
