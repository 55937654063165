export default {
  Administrador: 'admin',
  Parceiro: 'partner',
  Gestor: 'staff',
  Usuario: 'user',
  admin: 'Administrador',
  partner: 'Parceiro',
  staff: 'Gestor',
  user: 'Usuario',
};

export const RoleColor = {
  admin: 'purple',
  partner: 'green',
  staff: 'volcano',
  user: 'cyan',
};
