import React, { useState, useEffect } from 'react';
import { Icon } from 'antd';
import { Link } from 'react-router-dom';

import { PageHeaderContainer } from './styles';

// ###############################################################################################
// ##
// ## VERIFICAR ESTE LINK PARA CRIAR UM BREADCRUMB ÚNICO
// ##
// ## https://ant.design/components/breadcrumb/#components-breadcrumb-demo-router-4
// ##
// ###############################################################################################

const itemRender = (route, _params, routes) => {
  const last = routes.indexOf(route) === routes.length - 1;
  if (!last) {
    if (!route.path || route.path === '') {
      return (
        <span className="no-link" style={{ color: '#003365' }}>
          {route.icon && (<Icon type={route.icon} className="gx-mr-2" />)}
          {route.breadcrumbName && (<span>{route.breadcrumbName}</span>)}
        </span>
      );
    }
    return (
      <Link to={route.path} style={{ color: '#038fdd' }}>
        {route.icon && (<Icon type={route.icon} className="gx-mr-2" style={{ fontSize: 16 }} />)}
        {route.breadcrumbName && (<span>{route.breadcrumbName}</span>)}
      </Link>
    );
  }
  return (<span style={{ color: '#003365' }}>{route.breadcrumbName}</span>);
};

export default function PageHeader({ config, params, subTitle, toolbar, children }) {
  const [breadcrumb, setBreadcrumb] = useState();

  useEffect(() => {
    if (config && config.routes && !!config.routes.length && params) {
      const routes = config.routes.map((item) => {
        const route = { ...item };
        Object.keys(params).forEach((key) => {
          if (item.breadcrumbName && item.breadcrumbName.includes(`:${key}`)) {
            route.breadcrumbName = item.breadcrumbName.replace(`:${key}`, params[key] || '...');
          }
          if (item.path && item.path.includes(`:${key}`)) {
            route.path = item.path.replace(`:${key}`, params[key]);
          }
        });
        return route;
      });
      setBreadcrumb({ routes, itemRender, separator: <Icon type="right" style={{ fontSize: 8 }} /> });
    }
  }, [config, params]);

  return (
    <PageHeaderContainer
      extra={toolbar}
      params={params}
      subTitle={subTitle}
      title={config.title}
      tags={config.tags}
      breadcrumb={breadcrumb || { routes: config.routes, itemRender, separator: <Icon type="right" style={{ fontSize: 8 }} /> }}
    >
      {children}
    </PageHeaderContainer>
  );
}
