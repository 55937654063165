import React, { useState, forwardRef } from 'react';

import { useApi, useApiPublic, useNotification } from '@core/hooks';
import Select from 'antd/lib/select';
import debounce from 'lodash/debounce';

const { Option } = Select;

function CidadesLazy({ className, uf, publicApi = false, value, placeholder, ...props }, ref) {
  const { api: Api } = useApi();
  const [data, setData] = useState();
  const notification = useNotification();
  const { api: ApiPublic } = useApiPublic();
  const [loading, setLoading] = useState(false);

  const loadData = async (term) => {
    if (term && term.length > 2) {
      setLoading(true);
      const api = publicApi ? ApiPublic() : Api;

      try {
        const { data: res } = await api.get(`/lazy/municipio/${uf}?term=${term}`);
        setData(res);
      } catch (err) {
        console.error(err);
        notification.error('Erro ao tentar carregar as cidades');
      } finally {
        setLoading(false);
      }
    }
  };

  const searchData = debounce(loadData, 800);
  return (
    <span ref={ref}>
      <Select
        showSearch
        labelInValue
        value={value}
        disabled={!uf}
        loading={loading}
        showArrow={false}
        filterOption={false}
        onSearch={searchData}
        defaultActiveFirstOption
        className={`gx-w-100 ${className || ''}`}
        placeholder={!uf ? 'Selecione o estado' : placeholder}
        {...props}
      >
        {value && (!data || !data.length) && (
          <Option key={value.key} value={value.key} label={value.label}>{value.label}</Option>
        )}
        {data && data.map((item) => (
          <Option key={item.codigo_ibge} value={item.codigo_ibge} label={item.nome}>{item.nome}</Option>
        ))}
      </Select>
    </span>
  );
}

export default forwardRef(CidadesLazy);
