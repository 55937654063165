import React, { useState, useEffect } from 'react';

export default function Flexbox({ children, flexDirection, wrap, alignItems, justifyContent, style, ...props }) {
  const [styles, setStyles] = useState({
    display: 'flex',
  });

  useEffect(() => {
    setStyles((prev) => {
      const css = { ...prev, ...style };
      if (flexDirection) {
        css.flexDirection = flexDirection;
      }
      if (alignItems) {
        css.alignItems = alignItems;
      }
      if (justifyContent) {
        css.justifyContent = justifyContent;
      }
      if (wrap) {
        css.flexWrap = wrap;
      }
      return css;
    });
  }, [flexDirection, alignItems, justifyContent, style]);

  return (
    <div style={styles} {...props}>
      {children}
    </div>
  );
}
