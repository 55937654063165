import React, { forwardRef } from 'react';

import { Badge, Select } from 'antd';
import * as Status from '@core/constants/Status';

const { Option } = Select;

const StatusFeatureSelect = ({ size, value = null, onChange, placeholder, width }, ref) => (
    <span ref={ref}>
        <Select
            value={value}
            size={size}
            placeholder={placeholder}
            style={{ width: width || '100%' }}
            onChange={onChange}
        >
            {Status.FeatureStatusTexto.map((texto, index) => (
                <Option key={index} value={index}>
                    <Badge size="large" color={Status.FeatureStatusCor[index]} />
                    {texto}
                </Option>
            ))}
        </Select>
    </span>
);

export default forwardRef(StatusFeatureSelect);
