import React from 'react';

import { Provider } from 'react-redux';

import 'styles/ducash.less';
import configureStore from '@core/redux/store';
import AppContainer from './containers/App';

export const store = configureStore();

const NextApp = () => (
  <Provider store={store}>
    <AppContainer />
  </Provider>
);

export default NextApp;
