import styled from 'styled-components';
import AntPageHeader from 'antd/lib/page-header';

export const PageHeaderContainer = styled(AntPageHeader)`
  background: transparent;
  /* border: 1px solid rgb(235, 237, 240); */
  border-radius: 5px;
  /* margin-bottom: 20px; */
  padding: 16px 0;

  & > .ant-page-header-heading {

    & > .ant-page-header-heading-title {
      font-size: 22px;
      text-transform: uppercase;
    }
    & > .ant-page-header-heading-extra {
      align-items: flex-end;
      display: flex;
      flex-direction: row;
      height: calc(100% - 32px);
      right: 0;
    }
  }
`;
