// / FEATURE STATUS
export const FeatureStatus = {
  Desativado: 0,
  Ativo: 1,
  Teste: 2,
  Desenvolvimento: 3,
};

export const FeatureStatusTexto = [
  'Desativado',
  'Ativo',
  'Teste',
  'Desenvolvimento',
];

export const FeatureStatusCor = [
  'silver',
  'green',
  'orange',
  'blue',
];

// STATUS ASSOCIADO GRUPO ZELO
export const StatusAssociado = {
  Ativo: 1,
  Suspenso: 2,
  Cancelado: 3,
};
