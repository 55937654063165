import React, { useState, useEffect, forwardRef } from 'react';

import { Select, Avatar } from 'antd';
import { useApi, useNotification } from '@core/hooks';
import Flexbox from '../visual/Flexbox';

const { Option } = Select;

const UsuariosSelect = ({ mode = 'default', style = {}, width, maxTagCount = 0, ...props }, ref) => {
  const { api: Api } = useApi();
  const [data, setData] = useState([]);
  const notification = useNotification();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      try {
        const { data: items } = await Api.get('/core/user');
        setData(items);
      } catch (err) {
        console.error(err);
        notification.error('Erro ao carregar os usuários');
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  const widthStyle = { width: width || '100%' };
  return (
    <span ref={ref}>
      <Select
        {...props}
        loading={loading}
        style={{ ...widthStyle, ...style }}
        mode={mode}
        maxTagCount={maxTagCount}
        showSearch
        optionFilterProp="label"
        filterOption={(input, option) => option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {data.map((item) => (
          <Option key={item.id} value={item.id} label={item.display_name}>
            <Flexbox flexDirection="row" alignItems="center">
              {!item.photo_url && (<Avatar icon="user" size={24} />)}
              {item.photo_url && (<Avatar size={24} src={item.photo_url} />)}
              <div className="gx-ml-2">{item.display_name}</div>
            </Flexbox>
          </Option>
        ))}
      </Select>
    </span>
  );
};

export default forwardRef(UsuariosSelect);
