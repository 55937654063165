import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Layout, Icon } from 'antd';

import App from 'app';
import { Loading } from '@core/components';

import logo from 'assets/logos/logo_topbar.png';
import * as LocalStorageConsts from '@core/constants/LocalStorageConsts';
import Topbar from './components/Topbar';
import SidebarMenu from './components/SidebarMenu';


import { Logo, Sidebar } from './styles';

const { Header, Content, Footer } = Layout;

export default function LayoutApp() {
  const { authUser, token } = useSelector((state) => state.auth);
  const [collapsed, setCollapsed] = useState(localStorage.getItem(LocalStorageConsts.SidebarCollapsed) === 'true');

  const toggle = () => {
    const sidebarCollapsed = !collapsed;
    localStorage.setItem(LocalStorageConsts.SidebarCollapsed, sidebarCollapsed);
    setCollapsed(sidebarCollapsed);
  };

  return (
    <Loading loading={!authUser || !token}>
      <Layout style={{ minHeight: '100vh' }}>
        <Sidebar
          collapsible
          collapsed={collapsed}
          onCollapse={toggle}
          trigger={
            collapsed ? <Icon type="menu-unfold" /> : <Icon type="menu-fold" style={{ fontSize: 18 }} />
          }
        >
          <Logo collapsed={collapsed}>
            <img src={logo} alt="Ducash" />
          </Logo>
          <SidebarMenu collapsed={collapsed} />
        </Sidebar>
        <Layout style={{ marginLeft: collapsed ? 80 : 200, transition: 'all .3s ease' }}>
          <Header style={{ background: '#fff', padding: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div />
            <Topbar />
          </Header>
          <Content style={{ overflow: 'initial' }}>
            <App />
          </Content>
          <Footer style={{ textAlign: 'center' }}>© Copyright 2015 - DUCASH. Todos os direitos reservados. CNPJ 21.318.015/0001-72</Footer>
        </Layout>
      </Layout>
    </Loading>
  );
}
