import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Flexbox, Label } from '@core/components';
import { putSessaoLoja } from '@core/redux/actions';
import { Icon, Dropdown, Avatar, Menu } from 'antd';
import Loading from '../visual/Loading';
import FieldControl from '../form/FieldControl';

export default function UnidadeSessao() {
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const { loja } = useSelector((state) => state.sessao);
  const { authUser } = useSelector((state) => state.auth);

  const loadData = async () => {
    if (authUser && authUser.lojas && !!authUser.lojas.length) {
      setData([...authUser.lojas]);
    }
  };

  const handleClick = ({ key }) => {
    if (key) {
      setLoading(true);
      const item = data.find((o) => o.id === parseInt(key));

      if (item) {
        dispatch(putSessaoLoja(item));
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (data && data.length) {
      dispatch(putSessaoLoja(data[0]));
    }
  }, [data]);

  if (loja) {
    const menu = (
      <Menu onClick={handleClick}>
        {data && data.map((item) => (
          <Menu.Item key={item.id} style={{ padding: 20, borderBottom: '1px solid #e8e8e8' }}>
            <Flexbox flexDirection="column">
              <Flexbox flexDirection="row">
                <Label theme="azul" uppercase bold block className="gx-ml-2">{item.nome}</Label>
              </Flexbox>
              <Flexbox flexDirection="column" style={{ marginLeft: 10 }}>
                <Label theme="preto" block>{`${item.logradouro}, ${item.numero}${item.complemento ? ` - ${item.complemento}` : ''},`}</Label>
                <Label theme="preto" block>{`${item.bairro} - ${item.cidade.nome}/${item.uf.sigla}`}</Label>
              </Flexbox>
            </Flexbox>
          </Menu.Item>
        ))}
      </Menu>
    );
    return (
      <Loading loading={loading}>
        <Flexbox
          flexDirection="row"
          className="gx-pointer"
          onClick={handleClick}
          style={{ borderRadius: 4, height: 60, padding: '6px 16px' }}
        >
          <Dropdown overlay={menu} trigger={['click']}>
            <FieldControl label="Loja">
              <Flexbox flexDirection="row" alignItems="center">
                {loja && (<Label bold>{loja.nome}</Label>)}
                {!loja && (<Label bold>Clique para escolher</Label>)}
                <Icon type="down" className="gx-ml-2" style={{ fontSize: 12 }} />
              </Flexbox>
            </FieldControl>
          </Dropdown>
        </Flexbox>
      </Loading>
    );
  }
  return <></>;
}
